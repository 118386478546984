import Container from "./Container";
import NavBar from "./NavBar";
import { BrowserRouter } from "react-router-dom";
import Bandeau from "./Bandeau";
import mandala from "./media/mandala.svg";
import Footer from "./Footer";
import { useEffect } from "react"; 
import loading from "./media/app_loading.svg";

function App() {
  const showWebsite = false;
  useEffect(() => {
    window.location.href = "https://www.hypnotherapeute-toulouse-budynek.fr";
  }, []);

  return (
    <div className="App">
      {showWebsite ? (
        <BrowserRouter>
          <img className="mandala" src={mandala} alt="mandala hypnose" />
          <Bandeau />
          <NavBar />
          <Container />
          <Footer />
        </BrowserRouter>
      ) : (
        <>
        <img src={loading} alt="loading" height={140} style={{ marginTop:100 }} />
        </>
      )}
    </div>
  );
}

export default App;
